<template lang="html">
    <div>
        <div class="wizard__avatar">
            <div class="wizard__title">
                Upload your avatar
            </div>
            <div class="attachments wizard__form">
                <avatar-loader
                    :avatar-fallback="getterPersonalInformation.avatar_fallback"
                    :avatar-url="getterPersonalInformation.avatar_url"
                />
            </div>
        </div>
        <div>
            <div class="wizard__title">
                Upload your documents
            </div>
            <div
                class="attachments wizard__form"
                :class="isProfile ? 'wizard__form-attachments--profile' : ''"
            >
                <div
                    id="view-add-form"
                    class="file-uploader"
                >
                    <!-- <h2 class="wizard__filetype-title">
                    {{ type.description }}
                </h2>
                <div> {{ type.type !== 'other' ? `Please upload your ${type.description}` : 'If you want, you can upload additional files' }} </div> -->
                    <form-select-document
                        :items="fileTypes"
                        placeholder="Select file type"
                        label="File type"
                        :err="error"
                        :errors="errors"
                        @selectType="setType"
                    />
                    <file-uploader
                        :max-file-size="8"
                        :type="type"
                        type-is-required
                        loading-url="/api/file/applicant/upload"
                        @error="validate"
                        @success="success"
                    />

                    <attachments-table
                        :uploaded-files="uploadedFiles"
                        :uploaded-files-own="uploadedFilesOwn"
                        delete-url="/api/file/applicant/delete"
                        @onDelete="deleteSuccess"
                    />
                </div>
                <div
                    v-if="showNavigation"
                    class="wizard-content__buttons"
                >
                    <custom-button
                        default
                        @on-btn-click="$emit('goToPrevStep', $event)"
                    >
                        Previous
                    </custom-button>
                    <custom-button
                        :disabled="!checkFiles"
                        default
                        @on-btn-click="goNext"
                    >
                        next
                    </custom-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/mixins/filtersMixin'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus'
import FileUploader from '@/components/FileUploader.vue';
import { getFileApplicantTypes } from '@/services/files/filesService'
import AttachmentsTable from '@/components/AttachmentsTable.vue';
import FormSelectDocument from '@/components/common/form-elements/FormSelectDocument.vue'
import AvatarLoader from '@/components/AvatarLoader';

export default {
    name: 'IndexVue',
    components: {
        FileUploader,
        AttachmentsTable,
        FormSelectDocument,
        AvatarLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        showNavigation: {
            type: Boolean,
            default: true
        },
        isProfile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uploadedFiles: [],
            uploadedFilesOwn: [],
            dialog: false,
            type: null,
            error: false,
            errors: null,
            types: [],
            fileTypes: []
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterPersonalInformation'
        ]),
        id() {
            return this.$route.params.id;
        },
        windowWidth() {
            return window.innerWidth;
        },
        checkFiles() {
            if (!this.uploadedFiles || !this.types.length) return false
            const typesArray = this.types.filter(({ description }) => description !== 'Other').map(({ description }) => description)
            const statusesArray = this.uploadedFiles.map((file) => file.type_description)
            return typesArray.every(type => statusesArray.includes(type))
        }
    },
    async mounted() {
        this.types = await getFileApplicantTypes()
        this.fileTypes = [...this.types]
        await this.getFiles();
        this.checkType()
    },
    methods: {
        checkType() {
            const statusesArray = this.uploadedFiles.map((file) => file.type_description).filter((type_description) => type_description !== 'Other')
            this.fileTypes = [...this.types]
            statusesArray.forEach((type_description) => {
                this.fileTypes = this.fileTypes.filter(({ description }) => description !== type_description)
            });
        },
        goNext() {
            this.$emit('goToNextStep')
        },
        getFiles() {
            return new Promise((resolve, reject) => {
                const vm = this;
                Api.get('/api/file/applicant/list-files', {
                    id: this.$route.params.id
                }, { dialog: true }, false).then((response) => {
                    vm.uploadedFiles = response.data;
                    this.$emit('uploadedFiles', this.uploadedFiles)
                    // this.sortList('created_at')
                    resolve(true)
                }).catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error');
                    reject(error)
                })
            })
        },
        async success(files) {
            this.uploadedFilesOwn = files
            this.uploadedFiles.push(...files)
            this.checkType()
        },
        async deleteSuccess(file_id) {
            this.uploadedFiles = this.uploadedFiles.filter((item) => item.file_id !== file_id)
            this.checkType()
        },
        setType(payload) {
            this.type = payload
            this.error = false
            this.errors = null
        },
        validate() {
            if (!this.type) {
                this.error = true
                this.errors = 'Need to select your file type'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    //.wizard__title, .wizard-radio-buttons, .file-uploader{
    //    padding: 0 30px!important;
    //}
    .wizard__filetype-title {
        margin-bottom: 10px;
    }
    .wizard__form-attachments--profile {
        .wizard__title, .wizard-radio-buttons, .file-uploader {
            padding: 0 30px!important;
            @media (max-width:480px) {
                padding: 0 15px !important;
            }
        }
        .file-uploader{
            padding-bottom: 30px !important;
            @media (max-width:480px) {
                padding-bottom: 15px !important;
            }
            .form-select{
                margin-top: 20px;
            }
        }
    }
    .file-uploader{
        .form-select{
            max-width: 300px;
        }
    }
</style>
