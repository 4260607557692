<template lang="html">
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <div class="profile wrapper">
                <div class="profile-header-block">
                    <wizard-attachments
                        :show-navigation="false"
                        :is-profile="true"
                    />
                </div>
            </div>
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import HeaderContent from '@/components/HeaderContent';
import WizardAttachments from '@/components/wizard/WizardAttachmentsWithSteps'

export default {
    name: 'ProfileDocuments',
    components: {
        WizardAttachments,
        MainLayout,
        HeaderContent
    },
    computed: {
        headerInfo() {
            return {
                title: 'Documents',
                className: 'header-content__account-setting',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Profile',
                        url: '/cp/profile'
                    }
                ]
            }
        }
    }
}
</script>
<style lang="scss">
.wizard__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: #181818;
    padding: 23px 40px 15px;
    border-bottom: 1px solid transparent!important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
        padding: 15px;
    }
}
</style>
