<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
        :class="{ 'error-border': err }"
    >
        <fieldset
            class="form-select__fieldset"
            :class="{ 'active': openListItem }"
        >
            <legend
                class="form-select__legend"
                :class="{ 'error': err }"
            >
                {{ label }}
            </legend>
            <component
                :is="prependIcon"
                class="form-input__prepend-icon"
            />
            <input
                class="form-select__input"
                :value="initValue"
                type="text"
                autocomplete="off"
                :name="name"
                :placeholder="placeholder"
                @click="openListItem = !openListItem"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in types"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item)"
                >
                    <span class="form-select__list-item-title">
                        {{ item.description }}
                    </span>
                </li>
            </ul>
            <div
                v-if="errors"
                class="form-select__error"
            >
                {{ errors }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import BookOpenVariant from 'mdi-vue/BookOpenVariant';
import FileDocument from 'mdi-vue/FileDocument';

export default {
    name: 'FormSelect',
    components: {
        BookOpenVariantIcon: BookOpenVariant,
        FileDocumentIcon: FileDocument
    },
    props: {
        value: {
            type: String
        },
        prependIcon: {
            type: String
        },
        items: {
            type: Array
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        name: {
            type: String
        },
        err: {
            type: Boolean
        },
        placeholder: {
            type: String
        }
    },
    data() {
        return {
            openListItem: false,
            searchItemName: null,
            filteredItems: [],
            search: '',
            initValue: null,
            types: null
        };
    },
    watch: {
        items: {
            handler() {
                this.types = [...this.items]
                this.initValue = null
                this.$emit('selectType', null)
            },
            deep: true
        }
    },
    created() {
        this.types = [...this.items]
    },
    methods: {
        async selectItem(item) {
            await this.setInitValue(item);
            this.openListItem = false;
            this.types = this.items
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.initValue = event.target.value
            this.types = this.items.filter(({ description }) => description.toLowerCase().includes(event.target.value.toLowerCase()))
        },
        setInitValue(item) {
            this.initValue = item.description;
            this.$emit('selectType', item.id)
        },
        clickOutside() {
            this.openListItem = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.form-select {
    outline: none;
    // border: 1px solid #27d91f ;
    -webkit-border-radius: $input-border-radius;
    -moz-border-radius: $input-border-radius;
    border-radius: $input-border-radius !important;
    max-height: 52px!important;
    width: 100%;
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }

    &__fieldset {
        position: relative;
        border: none ;
        outline: none;
        &:after{
            content: '';
            width: 12px;
            height: 12px;
            background: url('~@/assets/img/svg/arrow.svg');
            display: block;
            position: absolute;
            right: 18px;
            top: calc(50% - 5px);
            transform: rotate(270deg);
            transition: .3s all;
            pointer-events: none;
        }
        &.active{
            &:after{
                transform: rotate(90deg);
            }
        }
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -12px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 10px;
        padding-right: 35px;
        width: 100%;
        cursor: pointer;
        line-height: 50px;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        max-height: 46px;
        user-select: none;
        &::-moz-selection {
            background: transparent;
        }
        &::selection {
            background: transparent;
        }
    }

    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
        &-title{
            width: 100%;
            // margin-bottom: 10px;
        }
        &-date{
            color: rgba(0,0,0,.6);
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}

</style>
